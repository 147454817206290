import React from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import MeritHeader from '../../components/about/MeritHeader';
import withTranslations from '../../components/hoc/withTranslations';

const Success = () => (
  <Layout>
    <Seo title='¡Registrado!' keywords={[`success`, `application`, `react`]} />
    <MeritHeader>¡Gracias por tu interés!</MeritHeader>
    <p>Mantente alerta para próximos cursos y eventos.</p>

    <p>
      Si tienes una duda,{' '}
      <a href='http://m.me/leavetheordinary'>
        <b>
          <u>contáctanos</u>
        </b>
      </a>
      .
    </p>
  </Layout>
);

export default withTranslations()(Success);
